import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'
import { handleFileInputs } from "@/stores/helpers/file-input";
import { handleSignaturePads } from "@/stores/helpers/signature-pad";
import { workflowInEdit } from "@/stores/workflowInEdit.store";
import { workflowPublished } from "@/stores/workflowPublished.store";
import { displayLogic } from "@/stores/displayLogic.store";
import { alert } from "@/stores/alert.store";
import { confirmation } from "@/stores/confirmation.store";
import { blocks } from "@/stores/blocks.store";
import { users } from "@/stores/users.store";
import { analytics } from "@/stores/analytics.store";
import { publishGuide } from "@/stores/publishGuide.store";
import { teap } from "@/stores/teap.store";
import { counties } from "@/stores/counties.store";
import { statisticsLogic } from "@/stores/statisticsLogic.store";
import { profile } from "@/stores/profile.store";
import { prices } from "@/stores/prices.store";
import { comments } from "@/stores/comments.store";
import { coupons } from "@/stores/coupons.store";
import { childCalculator } from "@/stores/ccLogic.store";
import { firebaseStorageDeleteDirectory, allLoaded } from "@/services/workflow";
import { nanoid } from "nanoid";
import semver from 'semver'
import {
  deleteTemplateImage,
  uploadTemplateImage,
  uploadImageGetURL,
  getTemplateImageURL,
} from "@/stores/helpers/index.helpers";
import fixUndefined from "@/utils/undefined.util";
import { promiseBlobToDataURL } from "@/services/workflow";
import { rulesQuestionChecker } from "@/utils/rules-question-checker.util";
import updateSubCollection from "@/services/updateSubCollection.service";
import { getCloudStorage } from "@/services/gcloud/getProject";
import $logger from "@/utils/logger.util";
import updateWorkflow from '@/services/workflow/updateWorkflow.service'
import {
  removeAllFormSubCollections,
} from "@/services/removeSubCollection.service";

Vue.use(Vuex);
Vue.config.devtools = true;

/**
 * Persistent Store
 *
 * The contents of this store persist between user sessions.
 */
export const vuexLocalStorage = new VuexPersistence({
  key: 'vuex-local-storage',
  storage: window.sessionStorage,
  modules: ['statisticsLogic','users','coupons']
})

const defaultState = () => {
  return {
    isCollaborate: true,
    title: "",
    firebaseConfig: {
      db: null,
      auth: null,
      functions: null,
      storage: null,
    },
    loadingOverlayStatus: false,
    overlayMessage: "Please Wait...",
    navItems: [
      {
        title: "Home",
        name: "home",
        icon: "home",
      },
      {
        title: "Divorce Forms",
        name: "legal-form-guide",
        icon: "search",
      },
      {
        title: "Shared Forms",
        name: "shared-forms",
        icon: "assignment",
      },
    ],
    navItemsAdmin: [
      {
        title: "Manage Workflows",
        name: "manage-workflows",
        icon: "dashboard",
        category: "Workflow Management",
      },
      {
        title: "Preview Workflows",
        name: "workflow-form-preview",
        icon: "description",
        category: "Workflow Management",
      },
      {
        title: "Published Requests",
        name: "pending-approvals",
        icon: "check_box",
        category: "Workflow Management",
      },
      {
        title: "Manage Users",
        name: "manage-users",
        icon: "mdi-account-group",
        category: "User Management",
      },
      {
        title: "Coupons",
        name: "coupon-manager",
        icon: "mdi-ticket-percent",
        category: "Workflow Management",
      },
      {
        title: "User Workflow Data",
        name: "user-workflow-data",
        icon: "description",
        category: "User Management",
      },
      {
        title: "Backup Database",
        name: "backup-database",
        icon: "mdi-database-export",
        category: "Support Tools",
      },
      {
        title: "Email Template Form",
        name: "email-form",
        icon: "email",
        category: "Support Tools",
      },
      {
        title: "PayPal Test",
        name: "paypal-test",
        icon: "attach_money",
        category: "Support Tools",
      },
      {
        title: "Statistics",
        name: "statistics",
        icon: "trending_up",
        category: "Support Tools",
      },
      {
        title: "Child Support Calculator",
        name: "ChildSupportCalculator",
        icon: "child_friendly",
        category: "Workflow Management",
      },
      {
        title: "Paywall prices",
        name: "PaywallPrices",
        icon: "attach_money",
        category: "Workflow Management",
      },
    ],
    formElementTypes: [
      {
        name: "single-select",
        canDialog: false,
      },
      {
        name: "state-select",
        canDialog: false,
      },
      {
        name: "multi-select",
        canDialog: false,
      },
      {
        name: "number-input",
        canDialog: false,
      },
      {
        name: "text-input-short",
        canDialog: true,
      },
      {
        name: "text-input-long",
        canDialog: true,
      },
      {
        name: "signature-pad",
        canDialog: true,
      },
      {
        name: "file-input",
        canDialog: false,
      },
      {
        name: "page-break",
        canDialog: false,
      },
      {
        name: "heading",
        canDialog: false,
      },
      {
        name: "subheading",
        canDialog: false,
      },
      {
        name: "paragraph",
        canDialog: false,
      },
      {
        name: "youtube-video",
        canDialog: false,
      },
      {
        name: "date-picker",
        canDialog: false,
      },
      {
        name: "dialog-window",
        canDialog: false,
      },
      {
        name: "computed-property",
        canDialog: false,
      },
      {
        name: "grouped-options",
        canDialog: false,
      },
      {
        name: "label",
        canDialog: false,
      },
      {
        name: "item-table",
        canDialog: false,
      },
      {
        name: "embedded-image",
        canDialog: false,
      },
      {
        name: "child-support-calculator",
        canDialog: false,
      },
    ],
    formElementOptions: {
      required: [
        "single-select",
        "state-select",
        "multi-select",
        "number-input",
        "text-input-short",
        "text-input-long",
        "signature-pad",
        "file-input",
        "date-picker",
        "dialog-window",
        "item-table",
        "embedded-image",
      ],
    },
    forms: [],
    formBuilderDialog: null,
    formBuilderActiveForm: {
      access: null,
      childrenOptions: null,
      collaborators: [],
      counties: null,
      description: null,
      docs: [],
      docsToDelete: null,
      fields: [],
      haveDuplicates: null,
      id: null,
      index: null,
      name: null,
      originalIndex: null,
      owner: {
        name: null,
        email: null,
      },
      stateholders: [],
      state: null,
      status: null,
      statusDateStamp: null,
      jurisdiction: null,
    },
    formBuilderDocIndex: 0,

    pdfActionTemplateDialog: null,
    pdfPagesLoadedFlag: false,
    pdfActionActive: "",
    pdfTemplateActions: [],
    statesList: [
      "Foreign Country or Territory",
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    newForm: false,
    workflowDuplicationStatus: false,
    acceptedUploadFileTypes: ".png, .gif, .jpeg, .jpg, .pdf",
    allowedUploadFiletypes: ".doc, .docx, .xls, .xlsx",
    activeFormIsDirty: false,
    baseUrl: "",
  };
};

export default new Vuex.Store({
  modules: {
    workflow: workflowInEdit,
    workflowPublished,
    displayLogic,
    statisticsLogic,
    publishGuide,
    profile,
    prices,
    coupons,
    comments,
    users,
    childCalculator,
    teap,
    counties,
    blocks,
    alert,
    confirmation,
    analytics
  },

  plugins: [vuexLocalStorage.plugin],

  state: defaultState(),

  mutations: {
    SET_OVERLAY_MESSAGE(state, message) {
      state.overlayMessage = message;
    },

    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },

    SET_TITLE(state, payload) {
      state.title = payload;
    },

    SET_DOCS_ON_FORM(state, { formId, docs }) {
      const index = state.forms.findIndex((f) => f.id === formId);
      if (index >= 0) {
        state.forms[index]["docs"] = docs;
      }
    },

    SET_FIELDS_ON_FORM(state, { formId, fields }) {
      const index = state.forms.findIndex((f) => f.id === formId);
      if (index >= 0) {
        state.forms[index]["fields"] = fields;
      }
    },

    SET_ACTIVE_FORM_FIELDS(state, payload) {
      state.formBuilderActiveForm.fields = payload;
    },

    SET_ACTIVE_FORM_DOCS(state, payload) {
      state.formBuilderActiveForm.docs = payload;
    },

    SET_BASE_URL(state, payload) {
      state.baseUrl = payload;
    },

    SET_NEW_FORM(state, payload) {
      state.newForm = payload;
    },

    SET_SIGNATURE_PAD_IMAGE_BY_ID(state, payload) {
      state.forms[payload.formIndex].fields[payload.fieldsIndex].signature.img =
        payload.img;
    },

    SET_FILE_INPUT(state, { formIndex, field }) {
      state.forms[formIndex].fields[field.fieldsIndex].content = field.content;
    },

    DELETE_FILE_INPUT_BY_ID(state, payload) {
      if (state.forms.length > 0 && payload.formIndex >= 0) {
        state.forms[payload.formIndex].fields[payload.fieldsIndex].content = {
          file: {},
          name: null,
          deleteFlag: true,
          storageRef: null,
          url: null,
        };
      }
    },

    SET_FILE_INPUT_BY_ID(state, payload) {
      state.forms[payload.formIndex].fields[payload.fieldsIndex].content.url =
        payload.url;
    },

    UPDATE_FORM_IS_DIRTY(state, value) {
      state.activeFormIsDirty = value;
    },

    TOGGLE_LOADING_OVERLAY_STATUS(state, value) {
      state.loadingOverlayStatus = value;
    },

    SET_FIREBASE_CONFIG(state, payload) {
      state.firebaseConfig = payload;
    },

    SET_PDF_TEMPLATE_ACTIONS(state, payload) {
      state.pdfTemplateActions = payload;
    },

    ADD_PDF_TEMPLATE_ACTION(state, payload) {
      state.pdfTemplateActions.push(payload);
    },

    DELETE_PDF_TEMPLATE_ACTION(state, index) {
      state.pdfTemplateActions.splice(index, 1);
    },

    UPDATE_PDF_TEMPLATE_ACTION(state, payload, index) {
      state.pdfTemplateActions[index] = payload;
    },

    TOGGLE_FORM_DIALOG(state, value) {
      state.formBuilderDialog = value;
    },

    SET_FORM_BUILDER_ACTIVE_FORM(state, form) {
      state.formBuilderActiveForm = form;
    },

    SET_FORM_BUILDER_DOC_INDEX(state, index) {
      state.formBuilderDocIndex = index;
    },

    ADD_DOCUMENT(state, newDocument) {
      state.formBuilderActiveForm.docs.push(newDocument);
    },

    EDIT_DOCUMENT(state, payload) {
      state.formBuilderActiveForm.docs[state.formBuilderDocIndex].name = payload.name;
    },

    DELETE_DOCUMENT(state, payload) {
      const docId = state.formBuilderActiveForm.docs[state.formBuilderDocIndex].uid;

      if (docId === payload.docId) {
        // docId only needs to be pushed into docsToDelete if it has pages stored in Cloud Storage
        if (state.formBuilderActiveForm.docs[state.formBuilderDocIndex]?.dbRefs ?.length > 0) {
          // Check if active workflow has docsToDelete property and initialize if not, otherwise push id of doc to be deleted
          if (!state.formBuilderActiveForm?.docsToDelete) {
            this.state.formBuilderActiveForm.docsToDelete = [docId];
          } else {
            this.state.formBuilderActiveForm.docsToDelete.push(docId);
          }
        }
        state.formBuilderActiveForm.docs.splice(state.formBuilderDocIndex, 1);
      }
    },

    SORT_DOCUMENT_PAGES(state, payload) {
      const docIndex = state.formBuilderActiveForm.docs.findIndex(
        (doc) => doc.uid === payload.docId
      );
      const existingPagesLength =
        state.formBuilderActiveForm.docs[docIndex]?.existingPages?.length ?? 0;
      const currentSessionPagesLength =
        state.formBuilderActiveForm.docs[docIndex]?.currentSessionPages
          ?.length ?? 0;

      if (existingPagesLength > 0) {
        state.formBuilderActiveForm.docs[docIndex].existingPages.sort(
          (a, b) => {
            if (a?.page && b?.page) {
              return a.page - b.page;
            } else {
              return 0;
            }
          }
        );

        for (let i = 0; i < existingPagesLength; i++) {
          state.formBuilderActiveForm.docs[docIndex].existingPages[i].page =
            i + 1;
        }
      }

      if (currentSessionPagesLength > 0) {
        state.formBuilderActiveForm.docs[docIndex].currentSessionPages.sort(
          (a, b) => {
            if (a?.page && b?.page) {
              return a.page - b.page;
            } else {
              return 0;
            }
          }
        );

        for (let i = 0; i < currentSessionPagesLength; i++) {
          state.formBuilderActiveForm.docs[docIndex].currentSessionPages[
            i
          ].page = existingPagesLength + (i + 1);
        }
      }
    },

    ADD_DOCUMENT_PAGE(state, payload) {
      // If document doesn't have an array for current session pages, add it
      if (
        !state.formBuilderActiveForm.docs[payload.docIndex]?.currentSessionPages
      ) {
        Vue.set(
          state.formBuilderActiveForm.docs[payload.docIndex],
          "currentSessionPages",
          []
        );
      }

      // If document doesn't have an array for existing pages, add it
      if (!state.formBuilderActiveForm.docs[payload.docIndex]?.existingPages) {
        Vue.set(
          state.formBuilderActiveForm.docs[payload.docIndex],
          "existingPages",
          []
        );
      }

      state.formBuilderActiveForm.docs[
        payload.docIndex
      ].currentSessionPages.push(payload.newPage);

      Vue.set(
        state.formBuilderActiveForm.docs[payload.docIndex],
        "combinedPages",
        [
          ...state.formBuilderActiveForm.docs[payload.docIndex].existingPages,
          ...state.formBuilderActiveForm.docs[payload.docIndex]
            .currentSessionPages,
        ]
      );
    },

    EDIT_DOCUMENT_PAGE(state, payload) {
      if (payload.pageIndex > -1) {
        if (payload.type === "existing-pages") {
          for (let property in payload.updates) {
            state.formBuilderActiveForm.docs[
              state.formBuilderDocIndex
            ].existingPages[payload.pageIndex][property] =
              payload.updates[property];
            state.formBuilderActiveForm.docs[state.formBuilderDocIndex].dbRefs[
              payload.pageIndex
            ][property] = payload.updates[property];
          }
        } else {
          for (let property in payload.updates) {
            state.formBuilderActiveForm.docs[
              state.formBuilderDocIndex
            ].currentSessionPages[payload.pageIndex][property] =
              payload.updates[property];
          }
        }
      }
    },

    MOVE_DOCUMENT_PAGE(state, payload) {
      const docIndex = state.formBuilderActiveForm.docs.findIndex(
        (doc) => doc.uid === payload.docId
      );
      const pageNewLocation = payload.pageTo;
      const pageOldLocation = payload.pageFrom;

      const existingPages =
        state.formBuilderActiveForm.docs[docIndex]?.existingPages ?? [];
      const currentSessionPages =
        state.formBuilderActiveForm.docs[docIndex]?.currentSessionPages ?? [];

      if (pageNewLocation !== pageOldLocation) {
        for (let i = 0; i < existingPages.length; i++) {
          if (existingPages[i].page > pageNewLocation) {
            state.formBuilderActiveForm.docs[docIndex].existingPages[
              i
            ].page += 1;
          }

          if (existingPages[i].page > pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].existingPages[
              i
            ].page -= 1;
          }
        }

        for (let i = 0; i < currentSessionPages.length; i++) {
          if (currentSessionPages[i].page > pageNewLocation) {
            state.formBuilderActiveForm.docs[docIndex].currentSessionPages[
              i
            ].page += 1;
          }

          if (currentSessionPages[i].page > pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].currentSessionPages[
              i
            ].page -= 1;
          }
        }

        if (payload.type === "existing") {
          if (pageNewLocation > pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].existingPages[
              payload.index
            ].page = pageNewLocation;
          } else if (pageNewLocation < pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].existingPages[
              payload.index
            ].page = pageNewLocation + 1;
          }
        } else if (payload.type === "current-session") {
          if (pageNewLocation > pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].currentSessionPages[
              payload.index
            ].page = pageNewLocation;
          } else if (pageNewLocation < pageOldLocation) {
            state.formBuilderActiveForm.docs[docIndex].currentSessionPages[
              payload.index
            ].page = pageNewLocation;
          }
        }
      }
    },

    DELETE_DOCUMENT_PAGE(state, payload) {
      switch (payload.type) {
        case "existing":
          if (
            !state.formBuilderActiveForm.docs[state.formBuilderDocIndex]
              ?.pagesToDelete
          ) {
            state.formBuilderActiveForm.docs[
              state.formBuilderDocIndex
            ].pagesToDelete = [];
          }
          // Push into array to have page deleted from database on workflow save if not a blank page
          if (
            state.formBuilderActiveForm.docs[state.formBuilderDocIndex]
              .existingPages[payload.pageIndex]?.file !== undefined
          ) {
            state.formBuilderActiveForm.docs[
              state.formBuilderDocIndex
            ].pagesToDelete.push({
              index: payload.pageIndex,
              name: state.formBuilderActiveForm.docs[state.formBuilderDocIndex]
                .existingPages[payload.pageIndex].name,
            });
          }
          // Remove the page from the existingPages property on the current doc
          state.formBuilderActiveForm.docs[
            state.formBuilderDocIndex
          ].existingPages.splice(payload.pageIndex, 1);
          state.formBuilderActiveForm.docs[
            state.formBuilderDocIndex
          ].dbRefs.splice(payload.pageIndex, 1);
          break;
        case "current-session":
          // Remove the page from the currentSessionPages property on the current doc
          state.formBuilderActiveForm.docs[
            state.formBuilderDocIndex
          ].currentSessionPages.splice(payload.pageIndex, 1);
          break;
      }
    },

    ADD_FORM_ELEMENT(state, formElement) {
      state.formBuilderActiveForm.fields.splice(
        formElement.fieldsIndex + 1,
        0,
        formElement
      );
    },

    UPDATE_FORM_FIELD(state, payload) {
      const fieldIndex = state.formBuilderActiveForm?.fields?.findIndex(
        (elem) => {
          if (elem?.uid && elem?.type && elem?.title) {
            return (
              elem.uid === payload.field.uid ||
              (elem.type === payload.field.type &&
                elem.title === payload.field.title)
            );
          } else {
            return false;
          }
        }
      );

      if (fieldIndex > -1) {
        for (let property in payload.updates) {
          Vue.set(
            state.formBuilderActiveForm.fields[fieldIndex],
            property.toString(),
            payload.updates[property]
          );
          // state.formBuilderActiveForm.fields[fieldIndex][property] = payload.updates[property];
        }
      }
    },

    ADD_RULE(state, payload) {
      const keysArrayLength = Object.keys(
        state.formBuilderActiveForm.docs[payload.docIndex].rules
      ).length;

      const newRule = {
        uid: nanoid(12),
        name: "",
        type: "",
        order: keysArrayLength + 1,
        condition: null,
        operator: null,
        questions: [
          {
            uid: null,
            title: null,
            type: "",
            selection: {},
            options: [],
          },
        ],
        displayLogicQuestionsDefault: true,
        displayLogicQuestions: [],
        actions: [],
      };

      Vue.set(
        state.formBuilderActiveForm.docs[payload.docIndex].rules,
        keysArrayLength,
        newRule
      );
    },

    ADD_ACTION(state, payload) {
      state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
        payload.ruleIndex
      ].actions.push(payload.action);
    },

    SET_AUTO_NUMBERING_LEVELS(state, payload) {
      Vue.set(
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction],
        "autoNumberingLevels",
        payload.newLevels
      );
    },

    ADD_AUTO_NUMBERING_LEVEL(state, payload) {
      const numberingLevels =
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction]?.autoNumberingLevels ?? [];
      numberingLevels.push(payload.newLevel);
      Vue.set(
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction],
        "autoNumberingLevels",
        numberingLevels
      );
    },

    DELETE_AUTO_NUMBERING_LEVEL(state, payload) {
      const numberingLevels =
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction]?.autoNumberingLevels ?? [];
      numberingLevels.splice(payload.levelToDelete, 1);
      Vue.set(
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction],
        "autoNumberingLevels",
        numberingLevels
      );
    },

    SET_FORM_BUILDER_PDF_PAGES_LOADED(state, value) {
      state.pdfPagesLoadedFlag = value;
    },

    TOGGLE_PDF_ACTION_FORM_DIALOG(state, value) {
      state.pdfActionTemplateDialog = value;
    },

    SET_PDF_ACTION_ACTIVE(state, value) {
      state.pdfActionActive = value;
    },

    SET_FORMS(state, forms) {
      state.forms = forms;
    },

    ADD_FORM(state, newForm) {
      state.forms.push(newForm);
    },

    DELETE_FORM(state, formIndex) {
      state.forms.splice(formIndex, 1);
    },

    UPDATE_FORM(state, payload) {
      let formIndex = state.forms.findIndex((x) => x.id === payload.id);

      for (let property in payload) {
        state.forms[formIndex][property] = payload[property];
      }
    },

    SET_WORKFLOW_DUPLICATION_STATUS(state, status) {
      state.workflowDuplicationStatus = status;
    },
  },

  getters: {
    overlayMessage: (state) => state.overlayMessage,

    title: (state) => state.title,

    formBuilderDialog: (state) => state.formBuilderDialog,

    formAlreadyExistsForState: (state) => (parentId, formId) => {
      const index = state.forms.findIndex(
        (f) => f.parentId === parentId && f.id !== formId && f.status != ""
      );
      return index >= 0;
    },

    isNewForm: (state) => state.newForm,

    getAcceptedUploadFileTypes: (state) => state.acceptedUploadFileTypes || "",

    getAllowedUploadFileTypes: (state) => state.allowedUploadFiletypes || "",

    isFormDirty: (state) => state.activeFormIsDirty,

    getPdfActionTemplateDialog: (state) => state.pdfActionTemplateDialog,

    loadingStatus: (state) => state.loadingOverlayStatus,

    getPdfPagesLoadedFlag: (state) => state.pdfPagesLoadedFlag,

    getStates: (state) => state.statesList,

    getFirebaseConfig: (state) => state.firebaseConfig,

    getFormElementTypes: (state) => state.formElementTypes,

    dialogElementTypes: (state) =>
      state.formElementTypes.filter((fe) => fe.canDialog === true),

    getFormElementOptions: (state) => state.formElementOptions,

    getForms: (state) => state.forms,

    getFormById: (state) => (id) => state.forms.find((form) => form.id === id),

    getPendingApprovals: (state) =>
      state.forms.filter(
        (form) =>
          form.status === "Pending" &&
          form.stakeholders.findIndex(
            (a) => a.email === state.profile.user.email
          ) >= 0
      ),

    getActiveForm: (state) => state.formBuilderActiveForm,

    getActiveFormId: (state) => state.formBuilderActiveForm.id,

    isLoading: ({ loadingOverlayStatus }) => loadingOverlayStatus,

    getFormDocsById: (state) => (id) =>
      state.forms
        .find((form) => form.id === id)
        .docs.sort((a, b) => {
          if (!a.order) {
            return 0;
          } else {
            return a.order < b.order ? 1 : -1;
          }
        }),

    getPdfTemplateActions: (state) => state.pdfTemplateActions,

    getPdfActionActive: (state) => state.pdfActionActive,

    getWorkflowFieldsAll: (state) => {
      if (state?.formBuilderActiveForm?.fields) {
        state.formBuilderActiveForm.fields.sort(
          (a, b) => a.fieldsIndex - b.fieldsIndex
        );
        const firstPageIndex = state.formBuilderActiveForm.fields.findIndex(
          (field) => field.type === "page-break"
        );
        if (firstPageIndex !== 0) {
          state.formBuilderActiveForm.fields.splice(
            0,
            0,
            ...state.formBuilderActiveForm.fields.splice(firstPageIndex, 1)
          );
        }
        return state.formBuilderActiveForm.fields;
      } else {
        return [];
      }
    },

    getWorkflowPages: (state, getters) => {
      const pages = [];

      const fields = getters.getWorkflowFieldsAll;
      const fieldsLength = fields.length;
      let pageStartIndex = 0;
      let currentPage = 1;

      for (let i = 0; i < fieldsLength; i++) {
        if (fields[i].type === "page-break" && i > 0) {
          pages.push(fields.slice(pageStartIndex, i));
          pageStartIndex = i;
          currentPage++;
        } else if (i === fieldsLength - 1) {
          pages.push(fields.slice(pageStartIndex));
          pageStartIndex = fieldsLength;
        }
        fields[i].page = currentPage;
      }

      return pages;
    },

    getStatistics: (state) => state.statistics,

    getFormBuilderDocIndex: (state) => state.formBuilderDocIndex,

    getWorkflowBuilderCurrentDoc: (state) =>
      state.formBuilderActiveForm.docs[state.formBuilderDocIndex],

    baseUrl: (state) => state.baseUrl,

    adminNavItems: (state) => state.navItemsAdmin,
  },

  actions: {
    setOverlayMessage({ commit }, message = 'Please Wait...') {
      commit("SET_OVERLAY_MESSAGE", message);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    setTitle({ state, commit }, payload) {
      commit("SET_TITLE", payload);
    },

    setBaseUrl({ commit }, payload) {
      commit("SET_BASE_URL", payload);
    },

    setNewForm({ commit }, payload) {
      commit("SET_NEW_FORM", payload);
    },

    async setWorkflowDuplicationStatus({ commit }, status) {
      commit("SET_WORKFLOW_DUPLICATION_STATUS", status);
      return;
    },

    readSignaturePadImage({ state, commit }, { field, formId }) {
      const storageRef = state.firebaseConfig.storage.ref();
      let formIndex = state.forms.findIndex((f) => f.id === formId);
      storageRef
        .child(field.signature.ref)
        .getDownloadURL()
        .then((url) => {
          if (formIndex < 0) {
            commit("workflowPublished/SET_SIGNATURE_PAD_IMAGE", {
              fieldsIndex: field.fieldsIndex,
              img: url,
            });
          } else {
            commit("SET_SIGNATURE_PAD_IMAGE_BY_ID", {
              fieldsIndex: field.fieldsIndex,
              img: url,
              formIndex,
            });
          }
        })
        .catch((error) => {
          if (error.code !== "storage/object-not-found") {
            throw 'storage object not found'
          } else if (formIndex) {
            commit("DELETE_FILE_INPUT_BY_ID", {
              fieldsIndex: field.fieldsIndex,
              formIndex,
            });
          }
        });
    },

    readFileInput({ state, commit }, { field, formId }) {
      const storageRef = state.firebaseConfig.storage.ref();
      let formIndex = state.forms.findIndex((f) => f.id === formId);
      storageRef
        .child(field.content.storageRef)
        .getDownloadURL()
        .then((url) => {
          if (formIndex < 0) {
            commit("workflowPublished/SET_FILE_INPUT", {
              fieldsIndex: field.fieldsIndex,
              url,
            });
          } else {
            commit("SET_FILE_INPUT_BY_ID", {
              fieldsIndex: field.fieldsIndex,
              url,
              formIndex,
            });
          }
        })
        .catch((error) => {
          if (error.code !== "storage/object-not-found") {
            throw 'storage object not found'
          } else {
            commit("DELETE_FILE_INPUT_BY_ID", {
              fieldsIndex: field.fieldsIndex,
              formIndex,
            });
          }
        });
    },

    setSignaturePadImage({ commit }, { field, formId }) {
      const formIndex = state.forms.findIndex((f) => f.id === formId);
      commit("SET_SIGNATURE_PAD_IMAGE_BY_ID", {
        fieldsIndex: field.fieldsIndex,
        img: field.signature.img,
        formIndex,
      });
    },

    setFileInput({ commit }, payload) {
      commit("SET_FILE_INPUT", payload);
    },

    resetActiveForm({ commit }) {
      commit("SET_FORM_BUILDER_ACTIVE_FORM", {});
      commit("UPDATE_FORM_IS_DIRTY", false);
    },

    //
    // App-Level Misc Actions
    //
    async toggleLoadingOverlayStatus({ commit }, value) {
      commit("TOGGLE_LOADING_OVERLAY_STATUS", value);
      return;
    },

    setFirebaseConfig({ commit }, config) {
      commit("SET_FIREBASE_CONFIG", config);
    },

    formIsDirty({ commit }) {
      commit("UPDATE_FORM_IS_DIRTY", true);
    },

    formIsClean({ commit }) {
      commit("UPDATE_FORM_IS_DIRTY", false);
    },
    //
    // FormBuilder Actions
    //

    setFormBuilderPagesLoadedFlag({ commit }, value) {
      commit("SET_FORM_BUILDER_PDF_PAGES_LOADED", value);
    },

    addFormElement({ commit, dispatch, state }, formElement) {
      return new Promise((resolve) => {
        commit("ADD_FORM_ELEMENT", formElement);

        // Update display logic
        let displayLogicRules = [];
        for (let i = 0; i < state.formBuilderActiveForm.docs.length; i++) {
          const filteredRules = state.formBuilderActiveForm.docs[
            i
          ]?.rules?.filter((rule) => rule.type === "Display Logic");
          if (filteredRules !== undefined)
            displayLogicRules = [...displayLogicRules, ...filteredRules];
        }

        dispatch("displayLogic/buildTemplates", {
          workflowId: state.formBuilderActiveForm.id,
          workflowType: "in-edit",
          formElements: [...state.formBuilderActiveForm.fields],
          rules: displayLogicRules,
        })
      });
    },

    // For use when updates do affect display logic
    updateFormBuilderActiveFormField({ commit, dispatch }, payload) {
      commit("UPDATE_FORM_FIELD", payload);
      dispatch("displayLogic/updateFormElementsDisplayStatus", {
        workflowType: "in-edit",
        formElementsToUpdate: [payload.field.uid],
      });
    },

    // For use when updates don't affect display logic
    async updateWorkflowField({ commit }, payload) {
      commit("UPDATE_FORM_FIELD", payload);
      return;
    },

    async addRule({ commit, state }, docIndex) {
      commit("ADD_RULE", { docIndex: docIndex });
      return;
    },

    async addAction({ commit }, payload) {
      commit("ADD_ACTION", payload);
      return;
    },

    async addAutoNumberingLevel({ commit, state }, payload) {
      if (
        state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
          payload.activeRule
        ].actions[payload.activeAction]?.autoNumberingLevels &&
        Array.isArray(
          state.formBuilderActiveForm.docs[state.formBuilderDocIndex].rules[
            payload.activeRule
          ].actions[payload.activeAction].autoNumberingLevels
        )
      ) {
        commit("ADD_AUTO_NUMBERING_LEVEL", payload);
      } else {
        commit("SET_AUTO_NUMBERING_LEVELS", {
          activeRule: payload.activeRule,
          activeAction: payload.activeAction,
          newLevels: [payload.newLevel],
        });
      }
      return;
    },

    setAutoNumberingLevels({ commit }, payload) {
      commit("SET_AUTO_NUMBERING_LEVELS", {
        activeRule: payload.activeRule,
        activeAction: payload.activeAction,
        newLevels: [payload.newLevel],
      });
    },

    async deleteAutoNumberingLevel({ commit }, payload) {
      commit("DELETE_AUTO_NUMBERING_LEVEL", payload);
      return;
    },

    async updateDocumentRender({ dispatch, state }) {
      if (
        state.formBuilderDocIndex < 0 ||
        Object.keys(state.formBuilderActiveForm).length === 0
      ) {
        return "no document index found"
      }

      let images = [];
      const doc = state.formBuilderActiveForm.docs[state.formBuilderDocIndex];
      if (doc?.existingPages) {
        images = [...images, ...doc.existingPages];
      }

      if (doc?.currentSessionPages) {
        images = [...images, ...doc.currentSessionPages];
      }

      await dispatch("workflow/setPdfTemplateImages", images)
      await dispatch("workflow/generatePdfFile", {
        type: "blob",
        templateFile: true,
        docIndex: state.formBuilderDocIndex,
      }).catch(error => {
        return error
      })
      await dispatch("workflow/generatePdfFile", {
        type: "blob",
        processedFile: true,
        docIndex: state.formBuilderDocIndex,
      }).catch(error => {
        return error
      })
      return
    },

    async setFormBuilderDocIndex({ commit, dispatch, state }, index) {
      commit("SET_FORM_BUILDER_DOC_INDEX", index);
      await Promise.all([
        dispatch("workflow/setWorkflowBuilderCurrentAction", null),
        dispatch("workflow/setWorkflowBuilderCurrentRule", null)
      ])
      await dispatch("updateDocumentRender");
      return;
    },

    async addDocument({ commit }, payload) {
      commit("ADD_DOCUMENT", payload);
      return;
    },

    async editDocument({ commit }, payload) {
      commit("EDIT_DOCUMENT", payload);
      return;
    },

    deleteDocument({ commit }, payload) {
      commit("DELETE_DOCUMENT", payload);
    },

    sortDocumentPages({ commit }, payload) {
      commit("SORT_DOCUMENT_PAGES", {
        docId: payload.docId,
      });
      return;
    },

    async addDocumentPage({ commit, dispatch }, payload) {
      commit("ADD_DOCUMENT_PAGE", payload);
      await dispatch("updateDocumentRender");
      return;
    },

    async editDocumentPage({ commit }, payload) {
      commit("EDIT_DOCUMENT_PAGE", payload);
      return;
    },

    async moveDocumentPage({ commit, dispatch }, payload) {
      commit("MOVE_DOCUMENT_PAGE", payload);
      commit("SORT_DOCUMENT_PAGES", {
        docId: payload.docId,
      });
      await dispatch("updateDocumentRender");
      return;
    },

    async deleteDocumentPage({ commit, dispatch }, payload) {
      commit("DELETE_DOCUMENT_PAGE", payload);

      // Sort & update page numbers
      commit("SORT_DOCUMENT_PAGES", { docId: payload.docId });
      await dispatch("updateDocumentRender");
      return;
    },

    async setFormBuilderActiveForm({ commit, dispatch, state }, form, formDocId = 0) {
      let filesToDownload = 0;
      const downloadPromises = [];
      let formCopy = {};

      formCopy = Object.assign({}, form);

      const formRef = state.firebaseConfig.db
        .collection("forms")
        .doc(formCopy.id);
      if (!formCopy.docs) {
        formCopy["docs"] = [];
        form["docs"] = [];
        let docs = (await formRef.collection("docs").get()).docs.map((d) => ({...d.data()}));

        if (docs.length > 0) {
          docs = docs.sort((a, b) => a.order - b.order);
        }
        formCopy.docs = docs;
        form.docs = docs;
      }

      formCopy.docs.forEach((doc) => {
        if (!doc.existingPages) {
          doc["existingPages"] = [...doc.dbRefs];
        }
      });

      if (!formCopy.fields) {
        formCopy["fields"] = [];
        form["fields"] = [];
        const fields = (await formRef.collection("fields").get()).docs.map(
          (d) => ({ ...d.data() })
        );

        formCopy.fields = fields;
        form.fields = fields;
      }

      if (form?.docs && form.docs.length > 0) {
        // Iterate through each document to set UID & increment fileToDownload, if there are any
        form.docs.forEach((doc, index) => {
          // Give doc a UID if it doesn't already have one
          if (!doc?.uid) {
            doc.uid = nanoid(12);
          }

          if (
            doc &&
            doc?.dbRefs &&
            Array.isArray(doc.dbRefs) &&
            doc.dbRefs.length
          ) {
            filesToDownload += doc.dbRefs.filter(
              (page) => !!page.dbRef
            ).length;
            formCopy.docs[index].existingPages = new Array(doc.dbRefs.length);
          }
        });

        // Iterate over docs and dbRefs in each document to download each template image for the document
        const formCopyDocsLength = formCopy.docs.length;
        for (let i = 0; i < formCopyDocsLength; i++) {
          let doc = formCopy.docs[i];

          // Store length of array for use
          const dbRefsLength = doc.dbRefs.length;
          if (doc.dbRefs && dbRefsLength > 0) {
            // Iterate over each of the dbRefs to download the template images
            for (let j = 0; j < dbRefsLength; j++) {
              // imageRef is JS object with file URL, name, other metadata
              let imageRef = doc.dbRefs[j];
              doc.existingPages.splice(j, 1, imageRef);
              if (imageRef.dbRef === "") {
                // If reference to file location is empty string, the page doesn't have a template image
                imageRef.file = null;
              } else {
                // Otherwise, download the file
                let downloadPromise = new Promise((resolveSecondary) => {
                  const dbRefUrl =
                    imageRef.dbRef.indexOf("http") < 0
                      ? `${getCloudStorage()}${imageRef.dbRef}`
                      : imageRef.dbRef;

                  fetch(dbRefUrl, { method: "GET" })
                    .then((response) => {
                      resolveSecondary({
                        docIndex: i,
                        dbRefIndex: j,
                        downloadPromise: response,
                      });
                    })
                });
                downloadPromises.push(downloadPromise);
              }
            }
          }
        }

        // When all downloads are finished, call allLoaded
        const dlpromises = await Promise.all(downloadPromises)
        for (const dlpromise of dlpromises) {
          let snapshot = dlpromise.downloadPromise;
          let blob = await snapshot.blob();
          let dataUrl = await promiseBlobToDataURL(blob);
          formCopy.docs[dlpromise.docIndex].dbRefs[
            dlpromise.dbRefIndex
          ].file = dataUrl;
          formCopy.docs[dlpromise.docIndex].existingPages[
            dlpromise.dbRefIndex
          ].file = dataUrl;
        }

        await allLoaded({ commit, dispatch, state }, formCopy, formDocId)
      } else {
        await allLoaded({ commit, dispatch, state }, formCopy, formDocId)
      }
      return
    },

    clearFormBuilderActiveForm({ commit }) {
      commit("SET_FORM_BUILDER_ACTIVE_FORM", {});
      return;
    },

    toggleFormBuilderDialog({ commit }, value) {
      // Prevents double vertical scroll bar when FormBuilder dialog is active
      if (value) {
        document.querySelector("html").style.overflowY = "hidden";
      } else {
        document.querySelector("html").style.overflowY = "scroll";
      }

      commit("TOGGLE_FORM_DIALOG", value);
    },

    togglePdfActionTemplateDialog({ commit }, value) {
      commit("TOGGLE_PDF_ACTION_FORM_DIALOG", value);
    },

    setPdfActionActive({ commit }, value) {
      commit("SET_PDF_ACTION_ACTIVE", value);
    },

    updatePdfActionActive({ commit }, payload, index) {
      commit("UPDATE_PDF_ACTION_ACTIVE", payload, index);
    },

    addPdfTemplateAction({ commit }, payload) {
      commit("ADD_PDF_TEMPLATE_ACTION", payload);
    },

    deletePdfTemplateAction({ commit }, index) {
      commit("DELETE_PDF_TEMPLATE_ACTION", index);
    },

    async getDocs({ state }, formId) {
      try {
        const docs = (
          await state.firebaseConfig.db
            .collection("forms")
            .doc(formId)
            .collection("docs")
            .get()
        ).docs.map((d) => ({ ...d.data() }));

        if (docs.length === 0)
          $logger.info(
            "*** store->getDocs->size of docs for formId: " + formId,
            docs.length
          );

        //commit('SET_DOCS_ON_FORM', { formId, docs })
      } catch (error) {
        throw "error setting docs for form id: " + formId
      } finally {
        return docs;
      }
    },

    async getFields({ state, commit }, formId) {
      try {
        const fields = (
          await state.firebaseConfig.db
            .collection("forms")
            .doc(formId)
            .collection("fields")
            .get()
        ).docs.map((d) => ({ ...d.data() }));

        commit("SET_FIELDS_ON_FORM", { formId, fields });
      } catch (error) {
        throw "error setting docs for form id: " + formId
      } finally {
        return fields;
      }
    },

    async formAdd({ commit, state }, newForm) {
      const activeStorageRequests = [];
      const activeDownloadURLRequests = [];
      const rootStorageRef = state.firebaseConfig.storage.ref();
      const tempDocuments = {};

      let newFormCopy = Object.assign({}, newForm);

      await Promise.all([
        handleFileInputs({
          state,
          form: newFormCopy,
          formId: newFormCopy.id,
        }),
        handleSignaturePads({
          state,
          form: newFormCopy,
          formId: newFormCopy.id,
        })
      ])

      const dialogWindowForm = newFormCopy.fields.filter(
        (f) => f.type === "dialog-window"
      );

      const signaturePadsToUpdate = []
      for (let dwf = 0; dwf < dialogWindowForm.length; dwf++) {
        const spf = dialogWindowForm[dwf];
        signaturePadsToUpdate.push(handleSignaturePads({
          state,
          form: spf,
          formId: newFormCopy.id,
          fieldsName: "dialogFields",
        }))
      }
      await Promise.all(signaturePadsToUpdate)

      newFormCopy.docs.forEach((doc) => {
        // Load each document's template pages data including file into this array, then push storage request for each
        // page that has a template image into activeStorageRequests
        doc.dbRefs = [];

        const newDbRefs = doc.currentSessionPages.map((currentPg) => {
          return {
            page: currentPg.page,
            name: currentPg.name,
            isNameUnique: currentPg.isNameUnique,
            dbRef: "",
            file: currentPg.file,
          }
        });

        tempDocuments[doc.uid] = newDbRefs;

        let formPath =
          "workflows-in-edit/" +
          newFormCopy.id.toString() +
          "/pdf-template-images/" +
          doc.uid.toString() +
          "/";

        newDbRefs.forEach((dbRef, index) => {
          let templateImage = dbRef.file;
          let pdfTemplateImageRef = rootStorageRef.child(formPath + dbRef.name);

          // Blank pages have undefined instead of a file; check for that here
          if (templateImage !== undefined) {
            activeStorageRequests.push(
              uploadTemplateImage(templateImage, pdfTemplateImageRef)
            );
          }
        });
      });

      Promise.all(activeStorageRequests).then(() => {
        for (let docId in tempDocuments) {
          const formPath =
            "workflows-in-edit/" +
            newFormCopy.id.toString() +
            "/pdf-template-images/" +
            docId.toString() +
            "/";

          tempDocuments[docId].forEach((page, index) => {
            let templateImage = page.file;
            let pdfTemplateImageRef = rootStorageRef.child(
              formPath + page.name
            );

            // If templateImage is undefined, it is a blank page; set dbRef to empty string
            if (templateImage === undefined) {
              page.dbRef = "";
            } else {
              // This also sets the dbRef property of the page via getTemplateImageURL
              activeDownloadURLRequests.push(
                getTemplateImageURL(
                  tempDocuments[docId],
                  index,
                  pdfTemplateImageRef
                )
              );
            }
          });
        }

        Promise.all(activeDownloadURLRequests).then(() => {
          for (let docId in tempDocuments) {
            tempDocuments[docId].forEach((page) => {
              delete page.file;
            });

            const docIndex = newFormCopy.docs.findIndex(
              (doc) => doc.uid === docId
            );
            newFormCopy.docs[docIndex].dbRefs = tempDocuments[docId];
          }

          // Save display-logic-elements, display-logic-library subcollections
          const workflowRef = state.firebaseConfig.db
            .collection("forms")
            .doc(newFormCopy.id);
          const docs = [...newFormCopy.docs];
          newFormCopy.docs = [];
          const fields = [...newFormCopy.fields];
          newFormCopy.fields = [];
          workflowRef
            .set(newFormCopy)
            .then(() => {
              //const logicLibraryPromises = [];
              const logicLibraryBatch = state.firebaseConfig.db.batch();
              for (let rule in state.displayLogic.logicLibrary) {
                logicLibraryBatch.set(
                  workflowRef.collection("display-logic-library").doc(rule),
                  state.displayLogic.logicLibrary[rule]
                );
                //logicLibraryPromises.push(workflowRef.collection('display-logic-library').doc(rule).set(state.displayLogic.logicLibrary[rule]));
              }
              logicLibraryBatch.commit();

              docs.forEach((doc) => {
                doc.dbRefs.forEach((dr) => {
                  dr.dbRef = dr.dbRef.replace(`${getCloudStorage()}`, "");
                });

                doc.existingPages.forEach((ep) => {
                  ep.dbRef = ep.dbRef.replace(`${getCloudStorage()}`, "");
                });

                doc.currentSessionPages = [];
                doc.combinedPages = [];
              });

              Promise.all([
                updateSubCollection({
                  parent: "forms",
                  id: newFormCopy.id,
                  sub: "blocks",
                  data: rootGetters["blocks/blocks"],
                }),
                updateSubCollection({
                  parent: "forms",
                  id: newFormCopy.id,
                  sub: "fields",
                  data: fixUndefined(fields),
                }),
                updateSubCollection({
                  parent: "forms",
                  id: newFormCopy.id,
                  sub: "docs",
                  data: fixUndefined(docs),
                })
              ])

              const formElementBatch = state.firebaseConfig.db.batch();
              for (let element in state.displayLogic.formElements) {
                formElementBatch.set(
                  workflowRef.collection("display-logic-elements").doc(element),
                  state.displayLogic.formElements[element]
                );
              }
              formElementBatch.commit();

              newFormCopy.docs = docs.sort((a, b) => a.order - b.order);
              newFormCopy.fields = fields;
              commit("ADD_FORM", newFormCopy);
            })
        });
      });
    },

    async formEdit({ state, rootGetters, commit }, activeForm) {
      const batch = state.firebaseConfig.db.batch();
      let activeDeleteRequests = [];
      let activeStorageRequests = [];
      const tempDocuments = {};
      const logicLibrary = rootGetters["displayLogic/logicLibrary"];
      const displayLogicFormElements = rootGetters["displayLogic/formElements"];

      // Clone workflow
      let activeFormCopy = Object.assign({}, activeForm);
      activeFormCopy.version = semver.inc(activeFormCopy.version, 'minor')
      activeFormCopy['modifiedDate'] = Date.now()
      const workflowRef = state.firebaseConfig.db
        .collection("forms")
        .doc(activeFormCopy.id);
      rulesQuestionChecker(activeFormCopy);
      // Save files uploaded to file-input form elements, signatures from signature-pad form elements
      await Promise.all([
        handleFileInputs({
          state,
          form: activeFormCopy,
          formId: activeFormCopy.id,
        }),
        handleSignaturePads({
          state,
          form: activeFormCopy,
          formId: activeFormCopy.id,
        })
      ])

      const rootStorageRef = state.firebaseConfig.storage.ref();
      const oldDirectory = "workflows/";
      const newDirectory = "workflows-in-edit/";
      let activeDirectory = oldDirectory;
      let formPath =
        activeDirectory +
        activeFormCopy.id.toString() +
        "/PDF_Template_Images/";

      // Save files uploaded to signature-pad form elements that are within other dialog-window form elements
      const dialogWindowForm = activeFormCopy.fields.filter(
        (f) => f.type === "dialog-window"
      );

      const signaturePadsToUpdate = []
      for (let dwf = 0; dwf < dialogWindowForm.length; dwf++) {
        const spf = dialogWindowForm[dwf];
        signaturePadsToUpdate.push(handleSignaturePads({
          state,
          form: spf,
          formId: activeFormCopy.id,
          fieldsName: "dialogFields",
        }))
      }
      await Promise.all(signaturePadsToUpdate)

      // Check to see that workflow doesn't use old directory 'workflows'. If it does, use that directory for file storage.
      const storageToEdit = await rootStorageRef.child("workflows-in-edit").listAll()
      storageToEdit.prefixes.forEach((workflowId) => {
        if (workflowId.name === activeFormCopy.id) {
          activeDirectory = newDirectory;
          formPath =
            activeDirectory +
            activeFormCopy.id.toString() +
            "/pdf-template-images/";
        }
      });

      // Delete any document directories flagged for deletion
      if (activeFormCopy?.docsToDelete) {
        activeFormCopy.docsToDelete.forEach(async (docId, index) => {
          const docRef = rootStorageRef.child(formPath + docId);
          await firebaseStorageDeleteDirectory(docRef);
          activeFormCopy.docsToDelete.splice(index, 1);
        })
      }

      //
      await Promise.all(
        activeFormCopy.docs.map(async (doc) => {
          // Initialize tempDocuments for current doc
          tempDocuments[doc.uid] = {};

          // Initialize dbRefs if they don't already exist in the document
          if (!doc?.dbRefs) {
            doc.dbRefs = [];
          }
          const existingDbRefs = doc.dbRefs;

          // Push delete requests into array
          if (doc?.pagesToDelete?.length > 0) {
            doc.pagesToDelete.forEach((page) => {
              let pageToDeleteName = page.name;
              let pageToDeleteRef =
                activeDirectory === oldDirectory
                  ? rootStorageRef.child(
                      formPath + pageToDeleteName.toString()
                    )
                  : rootStorageRef.child(
                      formPath + doc.uid + "/" + pageToDeleteName.toString()
                    );
              let imgIndex = existingDbRefs.findIndex(
                (element) => element.name === pageToDeleteName
              );

              //doc.dbRefs.splice(imgIndex, 1);

              activeDeleteRequests.push(pageToDeleteRef);
              activeStorageRequests.push(
                deleteTemplateImage(pageToDeleteRef)
              );
            });
          }
          delete doc.pagesToDelete; // Remove pagesToDelete after use

          //
          // Move existing pages into tempDocuments
          //
          if (doc?.existingPages && doc.existingPages.length > 0) {
            tempDocuments[doc.uid].existingPages = [...doc.existingPages];
          } else {
            tempDocuments[doc.uid].existingPages = [];
          }

          //
          // Push storage requests into array, move currentSessionPages into existingPages
          //
          if (doc?.currentSessionPages?.length > 0) {
            await Promise.all(
              doc.currentSessionPages.map(async (currentPage) => {
                const templateImage = currentPage.file;
                let templateImageURL = "";
                const pdfTemplateImageRef = rootStorageRef.child(
                  formPath + doc.uid + "/" + currentPage.name.toString()
                );

                // Blank pages have undefined instead of a file; check for that here
                if (templateImage !== undefined) {
                  templateImageURL = await uploadImageGetURL(
                    templateImage,
                    pdfTemplateImageRef
                  );
                  activeStorageRequests.push(templateImageURL);
                }

                const newPage = {
                  page: currentPage.page,
                  name: currentPage.name,
                  isNameUnique: true,
                  dbRef: templateImageURL,
                };

                // Move newPage into dbRefs
                doc.dbRefs.push(newPage);

                // Add file back in so it doesn't have to be re-downloaded, dbRef because Promises are not copied by Object.assign
                const newPageCopy = Object.assign({}, newPage);
                newPageCopy.file = templateImage;
                newPageCopy.dbRef = templateImageURL.replace(
                  getCloudStorage(),
                  ""
                );

                // Now move current pages to existing pages
                tempDocuments[doc.uid].existingPages.push(newPageCopy);
              })
            );
          } // Delete combinedPages because we have to get rid of files before we write the workflow to Cloud Firestore and now combinedPages will be just existingPages because there are no currentSessionPages after they've been saved
        })
      );

      // After all delete requests have been resolved
      await Promise.all(activeDeleteRequests)
      // After all files are saved to Cloud Storage
      await Promise.all(activeStorageRequests)
      // Add currentSessionPages, existingPages, combinedPages, pagesToDelete back in
      activeFormCopy.docs.forEach((doc) => {
        doc.currentSessionPages = [];
        doc.pagesToDelete = [];
        doc.combinedPages = [];
        doc.existingPages = tempDocuments[doc.uid].existingPages.filter((ep) => ep) ?? [];

        doc.dbRefs.forEach((dbRef) => {
          dbRef.dbRef = dbRef.dbRef.replace(`${getCloudStorage()}/`, "");
          dbRef.file = null;
        });

        doc.existingPages.forEach((ep) => {
          ep.file = null;
          ep.dbRef = ep.dbRef.replace(`${getCloudStorage()}/`, "");
        });
      });

      const docs = [...activeFormCopy.docs];
      const fields = [...activeFormCopy.fields];
      const blocks = [...rootGetters["blocks/blocks"]];
      const fixedDocs = fixUndefined(docs)
      const fixedFields = fixUndefined(fields)
      const docIdsToDelete = activeFormCopy?.docsToDelete?.map(d => d.uid) || []
      const filteredDocs = fixedDocs.filter(d => !docIdsToDelete.includes(d.uid))
      delete activeFormCopy.docs;
      delete activeFormCopy.fields;
      const updatePayload = {
        formData: activeFormCopy,
        fields: fixedFields,
        docs: filteredDocs,
        blocks,
        displayLogicElements: Object.values(displayLogicFormElements),
        displayLogicLibrary: Object.values(logicLibrary)
      }
      await updateWorkflow(updatePayload)
      activeFormCopy.docs = docs.sort((a, b) => a.order - b.order);
      activeFormCopy.fields = fields;

      commit("UPDATE_FORM", activeFormCopy) 

      return
    },

    async setForms({ state, commit }) {
        let userRef = state.firebaseConfig.auth.currentUser;
        let userEmail = userRef.email;
        const db = state.firebaseConfig.db;
        const userRole = (await db.collection("users").doc(userEmail).get()).data().role

        const forms = (await db
          .collection("forms")
          .where("access", "array-contains-any", [userRole, userEmail])
          .get()).docs.map(f => f.data())
        let formsRetrieved = [];

        forms.forEach(async (formData) => {
          let formTemplate = {};

          formTemplate["id"] = formData.id;

          for (let property in formData) {
            formTemplate[property] = formData[property];
          }

          formsRetrieved.push(formTemplate);
        });

        commit("SET_FORMS", formsRetrieved);
        return
    },

    async removeForms({ state, commit }, deleteFormMetadata) {
      // Delete stored template images
      let rootStorageRef = state.firebaseConfig.storage.ref();

      let formPath = 'forms/' + deleteFormMetadata.id;

      await Promise.all([
        firebaseStorageDeleteDirectory(rootStorageRef.child(formPath)),
        removeAllFormSubCollections({ parent: 'forms', id: deleteFormMetadata.id }),
        state.firebaseConfig.db
          .collection("forms")
          .doc(deleteFormMetadata.id)
          .delete()
      ])
      commit("DELETE_FORM", deleteFormMetadata.index);
    },

    updateFormAccessAndStakeHolders({ state, commit }, payload) {
      state.firebaseConfig.db
        .collection("forms")
        .doc(payload.id)
        .set(payload, { merge: true })
        .then(() => {
          commit("UPDATE_FORM", payload);
        })
    },
  },
});
