import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      pageNames: [],
      pages: []
    }
  },

  computed: {
    ...mapGetters("workflowPublished", ["getWorkflowFieldsAll"])
  },

  methods: {
    setPageNames() {
      if(!this.getWorkflowFieldsAll) this.pageNames = [];
      this.pageNames = this.getWorkflowFieldsAll
        .filter(f => f?.type && f.type === 'page-break')
        .sort((a, b) => a.fieldsIndex - b.fieldsIndex)
        .map((f, i) => f?.name ? f.name : 'Page ' + (i + 1));
    },

    setPages() {
      const pages = [];
      const fields = this.sortedFields();
      const fieldsLength = fields.length;
      let pageStartIndex = 0;
      let currentPage = 1;

      for (let i = 0; i < fieldsLength; i++) {
        if (fields[i].type === 'page-break' && i > 0) {
          pages.push(fields.slice(pageStartIndex, i));
          pageStartIndex = i;
          currentPage++;
        } else if (i === fieldsLength -1) {
          pages.push(fields.slice(pageStartIndex));
          pageStartIndex = fieldsLength;
        }
        fields[i].page = currentPage;
      }

      this.pages = pages;
    },

    replaceNames() {
      if (typeof this.pages[0] !== 'undefined' && typeof this.pages[1] !== 'undefined') {
        const formElementsF = this.pages[0] && this.pages[0]
        const formElementsS = this.pages[1] && this.pages[1]
        const getFirstName = formElementsF.filter(e => e.title === 'PET_FN')
        const getLastName = formElementsS.filter(e => e.title === 'RES_FN')
        const firstName = getFirstName[0].content
        const lastName = getLastName[0].content
        this.pageNames.splice(0, 2)
        this.pageNames.unshift(firstName === '' ? 'You' : firstName, lastName === '' ? 'Your spouse' : lastName)
      }
    },

    sortedFields() {
      const formFields = this.formFields
      if (formFields) {
        formFields.sort((a,b) => a.fieldsIndex - b.fieldsIndex);
        const firstPageIndex = formFields.findIndex(field => field.type === 'page-break');
        if (firstPageIndex !== 0) {
          formFields.splice(0, 0, ...formFields.splice(firstPageIndex, 1))
        }
        return formFields;
      } else {
        return [];
      }
    },
  },

  mounted() {
    this.setPageNames()
    this.setPages()
    this.replaceNames()
  }
}
