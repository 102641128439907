<template>
  <v-row>
    <v-col cols="3" xl="2">
      <v-card
        tile
        height="100%"
        elevation="4"
        max-width="400"
        class="mx-auto"
      >
        <v-card-title class="bg-primary white--text">Form Elements</v-card-title>
        <v-text-field 
          v-if="showSearch" 
          v-model="searchDisplayLogic" 
          placeholder="Search Form Elements..."
          clearable
          solo
          class="a-action-list-filter"
        />
        <v-select 
          :items="['Incomplete','Completed']"
          placeholder="Filter by status"
          v-model="filterBy"
          clearable
          solo
          class="a-action-list-filter"
        />
        <v-select 
          :items="pageNames"
          placeholder="Filter by page"
          v-model="pageName"
          clearable
          solo
          class="a-action-list-filter"
        />
        <v-virtual-scroll 
          :items="displayLogicItems"
          :height="contentHeight - 168"
          bench="4"
          item-height="64"
          class="border-solid-1"
        >
          <template v-slot:default="{ item }">
            <v-list-item
              :id="item.uid"
              :key="item.uid"
              :class="{ 'm-active-list-item': selectedIndex === item.fieldsIndex }"
              class="form-element-container"
              @click="activateFormElement(item.fieldsIndex)"
            >
              <v-list-item-action>
                <v-btn
                  fab
                  small
                  depressed
                  color="primary"
                >
                  {{ item.fieldsIndex }}
                </v-btn>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title :title="item.title">
                  <div class="text-left font-size-x-small">
                    <strong>{{ item.title }}</strong>
                    <br>
                    <span>{{ item.type }}</span>
                    <v-icon 
                      v-if="hasDisplayLogic(item.uid)" 
                      :color="displayLogicColor(item.uid)" 
                      large 
                      title="Has Display Logic"
                      class="hasDisplayLogic">mdi-circle-small</v-icon>
                  </div>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon small>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-col>
    <v-col cols="9" xl="10">
      <v-card
          tile
          height="100%"
          elevation="4"
          width="100%"
          class="mx-auto">
        <v-card-title class="bg-primary white--text">Display Logic</v-card-title>
        <v-card-text :style="{ height: (contentHeight - 104) + 'px'}" class="overflow-y-auto border-solid-1">

          <v-row class="full-width my-2">
            <v-col cols="6">
              <h4 class="text-left font-weight-800 black--text">{{ fields[activeFormElement].title }}</h4>
              <h4 class="text-left"><i>"{{ fields[activeFormElement].label }}"</i></h4>
            </v-col>
            <v-col 
              cols="6"
              class="d-flex justify-center align-center"
            >
              <div v-if="activeNode && activeNode.node_type !== 'library'" >
                <div>
                  <label v-if="activeNode" class="mr-4"><span class="text-quinary">Active Node</span> Name</label>
                </div>
                <v-text-field 
                  v-if="activeNode" 
                  v-model="activeNode.name" 
                  placeholder="e.g. A & B, not C" 
                  solo
                  class="solo-height-fix"
                />
              </div>
              <v-switch 
                v-model="displayLogicActiveElement.hideByDefault" 
                label="Hide" 
                class="px-4"
                style="position:absolute; right: 5px;"
                @change="handleDefaultDisplayLogic(fields[activeFormElement].uid, displayLogicActiveElement.hideByDefault)"
              />
            </v-col>
          </v-row>

          <v-divider/>

          <!-- Levels -->
          <DisplayLogicLevel :level="displayLogicActiveElement" @active-node-change="activateNode($event, 1)"
                             :lowest-level="lowestActiveLevel" :level-position="1" ref="level-1"
                             @active-node-deleted="deleteActiveNode($event, 1)"/>
          <DisplayLogicLevel v-if="activeNodeLevelTwo" :level="activeNodeLevelTwo" :lowest-level="lowestActiveLevel"
                             :level-position="2" ref="level-2"
                             @active-node-change="activateNode($event, 2)"
                             @active-node-deleted="deleteActiveNode($event, 2)"/>
          <DisplayLogicLevel v-if="activeNodeLevelThree" :level="activeNodeLevelThree" :lowest-level="lowestActiveLevel"
                             :level-position="3" ref="level-3"
                             @active-node-change="activateNode($event, 3)"
                             @active-node-deleted="deleteActiveNode($event, 3)"/>
          <DisplayLogicLevel v-if="activeNodeLevelFour" :level="activeNodeLevelFour" :lowest-level="lowestActiveLevel"
                             :level-position="4" ref="level-4"
                             @active-node-change="activateNode($event, 4)"
                             @active-node-deleted="deleteActiveNode($event, 4)"/>
          <DisplayLogicLevel v-if="activeNodeLevelFive" :level="activeNodeLevelFive" :lowest-level="lowestActiveLevel"
                             :level-position="5" ref="level-5"
                             @active-node-change="activateNode($event, 5)"
                             @active-node-deleted="deleteActiveNode($event, 5)"/>
          <DisplayLogicLevel v-if="activeNodeLevelSix" :level="activeNodeLevelSix" :lowest-level="lowestActiveLevel"
                             :level-position="6" ref="level-6"
                             @active-node-change="activateNode($event, 6)"
                             @active-node-deleted="deleteActiveNode($event, 6)"/>
          <DisplayLogicLevel v-if="activeNodeLevelSeven" :level="activeNodeLevelSeven" :lowest-level="lowestActiveLevel"
                             :level-position="7" ref="level-7"
                             @active-node-change="activateNode($event, 7)"
                             @active-node-deleted="deleteActiveNode($event, 7)"/>
          <DisplayLogicLevel v-if="activeNodeLevelEight" :level="activeNodeLevelEight" :lowest-level="lowestActiveLevel"
                             :level-position="8" ref="level-8"
                             @active-node-change="activateNode($event, 8)"
                             @active-node-deleted="deleteActiveNode($event, 8)"/>
          <DisplayLogicLevel v-if="activeNodeLevelNine" :level="activeNodeLevelNine" :lowest-level="lowestActiveLevel"
                             :level-position="9" ref="level-9"
                             @active-node-change="activateNode($event, 9)"
                             @active-node-deleted="deleteActiveNode($event, 9)"/>
          <DisplayLogicLevel v-if="activeNodeLevelTen" :level="activeNodeLevelTen" :lowest-level="lowestActiveLevel"
                             :level-position="10"  ref="level-10"
                             :last-level="true"
                             @active-node-change="activateNode($event, 10)"
                             @active-node-deleted="deleteActiveNode($event, 10)"/>

          <!-- Expression Configuration -->
          <DisplayLogicExpression 
            v-if="activeNode && activeNode.node_type === 'expression'"
            :rule="activeNode"
            :parsed-question-list="parsedQuestionList"/>

          <!-- Library Configuration -->
          <DisplayLogicLibrary 
            v-if="activeNode && activeNode.node_type === 'library'"
            :ruleUid="activeNode.library_uid"
            :parsed-question-list="parsedQuestionList"
            @active-rule-updated="setLibraryReference" />

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import displayLogicConfigurationMixin from '@/mixins/components/displayLogicConfiguration.mixin';

export default {
  name: 'DisplayLogicConfiguration',

  mixins: [displayLogicConfigurationMixin]
}
</script>

<style lang="scss">
.form-element-container {
  position: relative;

  .hasDisplayLogic {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>